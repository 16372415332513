import React from 'react';

const AreaImg = (props) => {
    return(
        <img src={props.imgArea} alt={props.altArea}/>
    )
}

export default AreaImg;

